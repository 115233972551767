import * as React from "react";
import { graphql, navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import * as styles from "../components/index.module.css";

import Layout from "../components/layout";
import PageError from "../components/PageError";
import {useTranslation, useI18next} from 'gatsby-plugin-react-i18next';
import { 
  Button, Paper, TextField, IconButton, 
  Slide, Typography, useMediaQuery
} from '@material-ui/core';
import {DropzoneArea} from 'material-ui-dropzone';
import DropDownList from "../components/DropDownList";
import Image from "../components/Image";
import { isMobile } from 'react-device-detect';
import * as MUIIcons from '@material-ui/icons';

import { connect } from 'react-redux';
import * as loggingActions from '../redux/actions/loggingActions';
import * as sessionActions from '../redux/actions/sessionActions';
import * as uiActions from '../redux/actions/uiActions';
import Api from '../lib/api';
import Helper from '../lib/helper';
import UIHelper from '../lib/uiHelper';
import UserHelper from '../lib/userHelper';
import jwt_decode from "jwt-decode";
import qs from 'qs';

import useStyles from '../assets/style/acceptInviteStyle';
import useCommonStyles from '../assets/style/commonStyle';
import clsx from 'clsx';

const userValidationInitState = {
  avatar: {error: false, touched: false, message: ''},
  email: {error: false, touched: false, message: ''},
  first_name: {error: false, touched: false, message: ''},
  last_name: {error: false, touched: false, message: ''},
  language: {error: false, touched: false, message: ''},
  location: {error: false, touched: false, message: ''},
  theme: {error: false, touched: false, message: ''},
  title: {error: false, touched: false, message: ''},
  newpassword: {error: false, touched: false, message: ''},
  confirmpassword: {error: false, touched: false, message: ''}
};

const AcceptInvitePage = (props) => {
  const {t} = useTranslation('acceptinvite');
  const {changeLanguage} = useI18next();
  const commonClasses = useCommonStyles();
  const classes = useStyles();
  const locale = props.ui.get('lang');
  const setting = Helper.getStateAsJSObj(props.system, 'setting', null);
  const appDetail = Helper.carefullyGetValue(setting, 'data.application'.split('.'), null);
  const sysCodes = Helper.getStateAsJSObj(props.system, 'setting.data.codes', []);
  const sysConfig = Helper.getStateAsJSObj(props.system, 'setting.data.configs', []);
  const logConfig = sysConfig.find(item => item.category === 'system' && item.identifier === 'system.logging');
  const logEnabled = Helper.carefullyGetValue(logConfig, 'value.log_enable'.split('.'), false);
  const consoleLogEnabled = Helper.carefullyGetValue(logConfig, 'value.console_log_enable'.split('.'), false);
  const [acceptStatus, setAcceptStatus] = React.useState(0); // 0=Not yet accept, 1=Accepting in progress, 2=Accepted
  const [acceptError, setAcceptError] = React.useState(''); 
  
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const displayMode = props.ui.get('displayMode');
  const displayModeToUse = displayMode === 'auto' ? (prefersDarkMode ? 'dark' : 'light') : displayMode;

  let passwordPolicy = Helper.carefullyGetValue(appDetail, ['passwordPolicy'], null);
  let regexPwd = Helper.carefullyGetValue(passwordPolicy, ['regex'], '');
  let policyLabelText ='';
  let policyLabel = Helper.carefullyGetValue(passwordPolicy, ['label'], []);
  if(Helper.arrayHasItem(policyLabel)) {
    let policyLabelLang = policyLabel.find(item => item.locale === locale);
    if(policyLabelLang) {
      policyLabelText = `${t('label.passwordplicy.note')}${policyLabelLang.text}`;
    }
  }

  const userFormRef = React.useRef(null);
  const [userFormAvatar, setUserFormAvatar] = React.useState(null);
  const [userValidation, setUserValidation] = React.useState(userValidationInitState);
  const userFields = [
    {field: 'avatar', required: false, regExp: "", type: 'string', label: 'userform.avatar'},
    {field: 'email', required: true, regExp: UIHelper.regExp.email, type: 'string', label: 'userform.email'},
    {field: 'first_name', required: true, regExp: '', type: 'string', label: 'userform.first_name'},
    {field: 'last_name', required: false, regExp: '', type: 'string', label: 'userform.last_name'},
    {field: 'language', required: false, regExp: '', type: 'string', label: 'userform.language'},
    {field: 'location', required: false, regExp: '', type: 'string', label: 'userform.location'},
    {field: 'theme', required: false, regExp: '', type: 'string', label: 'userform.theme'},
    {field: 'title', required: false, regExp: '', type: 'string', label: 'userform.title'},
    {field: 'newpassword', required: true, regExp: regexPwd, type: 'string', label: 'userform.newpassword'},
    {field: 'confirmpassword', required: true, regExp: regexPwd, type: 'string', label: 'userform.confirmpassword'}
  ];

  let queryParam = null;
  if(Helper.stringHasValue(props.location?.search))
    queryParam = qs.parse(props.location.search.substr(1));

  let decoded = null;
  let tokenExpired = false;
  let invalidToken = Helper.carefullyGetValue(queryParam, ['token'], '').length <= 0;
  if(!invalidToken) {
    // If have token, make sure the token is still valid.
    try {
      decoded = jwt_decode(queryParam.token);
      tokenExpired = new Date().getTime() >= (decoded.exp * 1000); // convert exp into milliseconds  
    } catch (error) {
      invalidToken = true;
    }
  }

  const userFormUI = () => {
    
    return(
    <>
      <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap, commonClasses.formFieldRow)}>
        <TextField id="email" label={t('userform.email')} 
          style={{flex: 1, minWidth: 200, marginBottom: 10}}
          variant="outlined" 
          disabled={true}
          defaultValue={Helper.carefullyGetValue(decoded, ['email'], '')} 
          error={userValidation.email.error}
          helperText={userValidation.email.message}
          onBlur={(event) => Helper.validateFormField('email', event.target.value, userFields, userValidation, setUserValidation, t)}
        />
        <TextField id="first_name" label={t('userform.first_name')} 
          style={{flex: 1, minWidth: 200, marginBottom: 10}}
          variant="outlined" 
          error={userValidation.first_name.error}
          helperText={userValidation.first_name.message}
          onBlur={(event) => Helper.validateFormField('first_name', event.target.value, userFields, userValidation, setUserValidation, t)}
        />
        <TextField id="last_name" label={t('userform.last_name')} 
          style={{flex: 1, minWidth: 200, marginBottom: 10}}
          variant="outlined" 
          error={userValidation.last_name.error}
          helperText={userValidation.last_name.message}
          onBlur={(event) => Helper.validateFormField('last_name', event.target.value, userFields, userValidation, setUserValidation, t)}
        />
      </div>
      <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap, commonClasses.formFieldRow)}>
        <TextField id="title" label={t('userform.title')} 
          style={{flex: 1, minWidth: 300, marginBottom: 10}}
          variant="outlined" 
          error={userValidation.title.error}
          helperText={userValidation.title.message}
          onBlur={(event) => Helper.validateFormField('title', event.target.value, userFields, userValidation, setUserValidation, t)}
        />
        <TextField id="location" label={t('userform.location')} 
          style={{flex: 1, minWidth: 200, marginBottom: 10}}
          variant="outlined" 
          error={userValidation.location.error}
          helperText={userValidation.location.message}
          onBlur={(event) => Helper.validateFormField('location', event.target.value, userFields, userValidation, setUserValidation, t)}
        />
      </div>
      <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap, commonClasses.formFieldRow)}>
        <DropDownList id="language" 
          variant="outlined"  
          style={{flex: 1, minWidth: 200, marginBottom: 10}}
          labelText={t('userform.language')} 
          defaultValue={'zh-CN'}
          options={sysCodes.filter(item => item.category === 'ui.language')}
          error={userValidation.language.error}
          helperText={userValidation.language.message}
        />
        <DropDownList id="theme" 
          variant="outlined"  
          style={{flex: 1, minWidth: 200, marginBottom: 10}}
          labelText={t('userform.theme')} 
          defaultValue={'auto'}
          options={sysCodes.filter(item => item.category === 'ui.theme')}
          error={userValidation.theme.error}
          helperText={userValidation.theme.message}
        />
      </div>
      <Typography variant='subtitle1' color="secondary" style={{marginBottom: 30}}>
          {policyLabelText}
      </Typography>
      <div className={clsx(commonClasses.flexRow, commonClasses.flexWrap, commonClasses.formFieldRow)}>
        <TextField id="newpassword" label={t('userform.newpassword')} 
          style={{flex: 1, minWidth: 300, marginBottom: 10}}
          variant="outlined" 
          autoComplete='new-password'
          type={'password'}
          error={userValidation.newpassword.error}
          helperText={userValidation.newpassword.message}
          onBlur={(event) => Helper.validateFormField('newpassword', event.target.value, userFields, userValidation, setUserValidation, t)}
        />
        <TextField id="confirmpassword" label={t('userform.confirmpassword')} 
          style={{flex: 1, minWidth: 200, marginBottom: 10}}
          variant="outlined" 
          autoComplete='new-password'
          type={'password'}
          error={userValidation.confirmpassword.error}
          helperText={userValidation.confirmpassword.message}
          onBlur={(event) => Helper.validateFormField('confirmpassword', event.target.value, userFields, userValidation, setUserValidation, t)}
        />
      </div>
    </>);
  }

  const userFormPhoto = <div className={clsx(commonClasses.flexRow, commonClasses.defaultPadding)} style={isMobile ? {position: 'relative', justifyContent: 'center', padding: 20} : {position: 'relative', flex: 2, justifyContent: 'center', padding: 20}}>
    {
      Helper.isNotNullAndUndefined(userFormAvatar) &&
      <div className={commonClasses.avatar} style={{overflow: 'clip', borderRadius: isMobile ? 125 : 150}}>
        <Image width={isMobile ? 250 : 300} height={isMobile ? 250 : 300} 
          objectFit={'fill'}
          src={userFormAvatar} 
        />
      </div>
    }
    {
      !Helper.isNotNullAndUndefined(userFormAvatar) &&
      <DropzoneArea
        filesLimit={1}
        //clearOnUnmount={false}
        classes={{
          root: commonClasses.avatarWithBorder
        }}
        // dropzoneClass={commonClasses.avatarWithBorder} 
        acceptedFiles={['image/*']}
        dropzoneText={t('userform.avatar.dropzone')}
        maxFileSize={10485760} // 10mb
        showPreviews={false}
        showPreviewsInDropzone={false}
        onChange={files => {
          setUserFormAvatar(Helper.arrayHasItem(files) ? files[0] : null);
        }}
        initialFiles={null}
      />
    }
    {
      Helper.isNotNullAndUndefined(userFormAvatar) &&
      <IconButton aria-label="delete" 
        style={{position: 'absolute', right: 10, top: 10, backgroundColor: '#fff3', width: 50, height: 50, borderRadius: 25}}
        onClick={() => {
          setUserFormAvatar(null);
        }}
      >
        <MUIIcons.Delete />
      </IconButton>
    }
  </div>

  const acceptInvitation = async () => {
    try {
      setAcceptStatus(0);
      setAcceptError('');
      let newPassword = Helper.carefullyGetValue(userFormRef, 'current.elements.newpassword.value'.split('.'), '');
      let confirmPassword = Helper.carefullyGetValue(userFormRef, 'current.elements.confirmpassword.value'.split('.'), '');
  
      let valid = Helper.validateFormRef(
        userFormRef, userFields, userValidation, 
        setUserValidation, t,
        _ => {
          let isValid = true;

          if(newPassword !== confirmPassword) {
            isValid = false;
            setAcceptError(t('error.pwdNotMatch.message'));
          }

          return isValid;
        }
      );
    
      if(valid) {
        setAcceptStatus(1);
        // userAcceptInvite will return undefined if success
        await Api.userAcceptInvite(queryParam.token, newPassword);

        let credential = {
          email: Helper.carefullyGetValue(decoded, ['email'], ''),
          password: newPassword
        };

        props.dispatch(
          // Perform a login
          sessionActions.authenticate(credential, false, async (success, authResult) => {
            if(success) {
              // Set the user id from authResult to userForm for later update user profile
              let formData = {
                id: authResult.id,
                avatar: null
              };

              // Upload profile photo if any
              if(Helper.isNotNullAndUndefined(userFormAvatar)) {
                let avatar = await Api.fileSet(null, userFormAvatar);
                if(Helper.isNotNullAndUndefined(avatar)) {
                  formData.avatar = avatar.id;
                }
              }

              userFields.forEach(item => {
                if(Helper.isNotNullAndUndefined(userFormRef.current.elements[item.field])) {
                  formData[item.field] = Helper.carefullyGetValue(userFormRef, `current.elements.${item.field}.value`.split('.'), '');
                }
              });

              // Update profile detail
              await Api.userUpdate(formData);

              // Update success, get the user profile
              props.dispatch(
                sessionActions.profileGet((success, result) => {
                  if(success && Helper.isNotNullAndUndefined(result)) {
                    if(Helper.stringHasValue(result.language) && result.language !== locale) {
                      props.dispatch(
                        uiActions.setLanguage(result.language)
                      );
                      changeLanguage(result.language);
                    }
  
                    if(Helper.stringHasValue(result.theme) && result.theme !== displayModeToUse) {
                      props.dispatch(
                        uiActions.setDisplayMode(result.theme)
                      );
                    }
  
                  }
  
                  setAcceptStatus(2);

                  // Determine the initial route after login for the user depend on the user role
                  let route = UserHelper.getHomeRouteByRole(setting, authResult.role);
                  if(route.length > 0)
                    navigate(route);
                })
              );
            }
          })
        );
      }
    } catch (error) {
      setAcceptStatus(0);
      setAcceptError(error.message);
      if(logEnabled) {
        error.message += ' - acceptInvitation Error @ /acceptinvite.js';
        props.dispatch(
          loggingActions.loggingNew(
            Helper.populateLoggingDetail(error, {
              session: props.session.toJS()
            })
          )
        );
      }

      consoleLogEnabled && console.error('userUpdate Error: ', error);
    }
  }

  return (
    <Layout showMenu={false} busy={acceptStatus === 1} backgroundImage={'/login_bg.jpg'} 
      childrenStyle={{
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {
        (invalidToken || tokenExpired) &&
        <PageError 
          style={{
            minHeight: 150,
            maxWidth: 500
          }}
          title={t(`error.${invalidToken ? 'invalidToken' : 'tokenExpired'}.title`)} 
          message={t(`error.${invalidToken ? 'invalidToken' : 'tokenExpired'}.message`)} 
          actionStyle={{
            justifyContent: 'center'
          }}
        />
      }
      {
        !invalidToken && !tokenExpired &&
        <Slide direction={'up'} in={true} mountOnEnter unmountOnExit>
          <Paper variant={'elevation'} elevation={4} className={classes.formBox}>
            <div className={styles.textCenter}>
              <StaticImage
                src="../images/app_logo.png"
                loading="eager"
                width={300}
                quality={95}
                formats={["auto"]}
                alt=""
                style={{ marginBottom: `var(--space-3)` }}
              />
              <h1>
                {t('prompt.acceptinvitation')}
              </h1>
            </div>
            {
              acceptStatus !== 2 &&
              <form ref={userFormRef} onSubmit={(event) => {
                  acceptInvitation();
                  event.preventDefault();
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {/* Create a hidden field for username for browser password manager and to prevent DOM warning. */}
                <input id="username" 
                  autoComplete='username'
                  value={decoded?.email} 
                  disabled={true}
                  hidden={true}
                />
                
                {
                  isMobile &&
                  <>
                    {userFormPhoto}
                    {userFormUI()}
                  </>
                }
                {
                  !isMobile &&
                  <div className={clsx(commonClasses.flexFullRow)}>
                    <div style={{flex: 6}}>
                      {userFormUI()}
                    </div>
                    {userFormPhoto}
                  </div>
                }
                {
                  Helper.stringHasValue(acceptError) &&
                  <Typography variant='subtitle1' color="error" className={classes.title}>
                    {acceptError}
                  </Typography>
                }
                <Button variant={'contained'} type='submit' 
                  color={'primary'} style={{marginBottom: 10, minWidth: 120}}
                >
                  {t('button.submit')}
                </Button>
                
              </form>
            }
            {
              acceptStatus === 2 &&
              <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
                <Typography variant='body1' style={{marginBottom: 30}}>
                  {t('label.acceptinvitation.message')}
                </Typography>
              </div>
            }
          </Paper>
        </Slide>
      }
    </Layout>
  );
}

export default connect((state) => {
  return {
    session: state.session,
    system: state.system,
    ui: state.ui
  };
})(AcceptInvitePage);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
