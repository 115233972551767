import { createStyles, makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';

const acceptInviteStyle = makeStyles((theme) => createStyles({
  formBox: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: isMobile ? '100vw' : 900,
    minHeight: 700,
    padding: 20,
    backgroundColor: theme.palette?.type === 'dark' ? '#111a' : '#fffa'
  }   
}));
export default acceptInviteStyle;
